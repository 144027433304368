<template>
    <div id="wrapper">
        <navbar id="nav" class="no-print animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn class="no-print"/>
                <div class="container-fluid text-dark">
                    <div class="row px-1 py-3 mb-1 no-print">
                        <label class="mt-1 col-xl-1 mb-2"> Month: </label>
                        <select class="form-control col-xl-1 col-md-4 mb-2" v-model="month" :disabled="changeZeroLoading">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <label class="mt-1 col-xl-1 mb-2"> Year: </label>
                        <input type="number" min="2020" class="form-control col-xl-1 col-md-4 mb-2" v-model="year" :disabled="changeZeroLoading">
                        
                        <button class="btn btn-success col-xl-2 col-md-4 mx-2 mb-2" v-on:click="search()" :disabled="changeZeroLoading"> Search <i class="fa fa-search"></i> </button>
                        <a class="btn btn-primary col-xl-2 col-md-4 mx-2 mb-2 text-white" data-toggle="modal" data-target="#mdlTotalSelection"  v-if="!user.en_id || user.role == 'A' || user.role == 'K'"> Total </a><hr>
                        <button class="btn btn-success col-xl-2 col-md-4 mx-2 mb-2" data-toggle="modal" data-target="#mdlDaydAndOvertime" v-if="!user.en_id && user.role == 'A'"> Days </button>
                        <a class="btn btn-warning col-xl-1 col-md-4 mx-2 mb-2 text-white" :href="'/zeroList/'" v-if="!user.en_id || user.role == 'A'"> Zeros </a><hr>
                    </div>
                    <div class="row px-1 py-3 mb-1 no-print" v-if="!user.en_id && user.role == 'A'">
                        <label class="mt-2 col-xl-1 mb-1"> 1$ = </label>
                        <input class="form-control col-xl-1 col-md-4 mb-2" v-model="dollar_price" type="number">
                    </div>
                    
                    <div class="row py-3 mb-1 no-print" dir="rtl" v-if="!user.en_id && user.role == 'A'">
                        <label class="mt-2 col-xl-1 mb-1"> بەڕێوبەر </label>
                        <input class="form-control col-xl-2 col-md-4 mb-2" v-model="manager" type="text" @change="changeNames()">

                        <label class="mt-2 col-xl-1 mb-1"> ب.دارایی </label>
                        <input class="form-control col-xl-2 col-md-4 mb-2" v-model="kargeri" type="text" @change="changeNames()">
                        <label class="mt-2 col-xl-1 mb-1"> سەرپەرشتیاری ستاف </label>
                        <input class="form-control col-xl-2 col-md-4 mb-2" v-model="supervisor" type="text">
                        <label class="mt-2 col-xl-1 mb-1"> ژمێریار </label>
                        <input class="form-control col-xl-2 col-md-4 mb-2" v-model="accountant" type="text" @change="changeNames()">
                    </div>
                    <hr class="no-print">
                    <button class="no-print btn btn-info btn-block mb-3" data-toggle="collapse" data-target="#staffs"> Staffs <i class="fa fa-arrow-down"></i> </button>
                    <div class="collapse" id="staffs">
                        <div class="row no-print">
                            <label class="col-sm-1 mt-1"> Staffs: </label>
                            <select v-model="staff_id" class="form-control col-sm-3 mb-2" :disabled="changeZeroLoading">
                                <option value=""></option>
                                <option v-for="staff in staffs.filter(obj => {
                                    if(user.role == 'E') return true;
                                    else return  obj.special_staff == showSpecialStaffs
                                    })" :key="staff.st_id" v-bind:value="staff.st_id"> {{ staff.staff_name }} </option>
                            </select>
                            <span class="col-1" v-if="!user.en_id && (user.role == 'A' || user.role == 'K')">
                                <v-checkbox class="mt-1 custom-checkbox-label" v-model="showSpecialStaffs" true-value="true" false-value="false" label="Specials"></v-checkbox>
                            </span>
                        </div>
                        <hr v-if="!user.en_id">
                        <div class="row no-print" v-if="!user.en_id && user.role == 'A'">
                            <label class="col-sm-1 mt-2"> Employee: </label>
                            <select v-model="emp_id" class="form-control col-sm-3 mb-2">
                                <option value=""></option>
                                <option v-for="emp in employees" :key="emp.emp_id" v-bind:value="emp.emp_id"> {{ emp.full_name }} </option>
                            </select>
                        </div>
                        <div class="row no-print" v-if="!user.en_id && user.role == 'A'">
                            <template v-if="getZerolLoading">
                                <v-progress-circular indeterminate class="mx-auto" color="primary"></v-progress-circular>
                            </template>
                            <template v-else> 
                                <div class="col-3" v-for="emp in employees" :key="emp.emp_id"> <v-checkbox :label="emp.full_name" :disabled="changeZeroLoading" :value="emp.emp_id" v-model="employees_to_zero" @click="checkZeroOrNot(emp.emp_id)"></v-checkbox> </div>
                            </template>
                        </div>
                    </div>
                    <v-dialog v-model="dialogNoteZeroList" max-width="700">
                        <v-card>
                            <v-card-title class="pb-0 pt-3">
                                Note
                            </v-card-title>
                            <v-divider class="mt-1"></v-divider>
                            <v-card-text>
                                <v-textarea rows="4" v-model="note_zero_list" outlined dense></v-textarea>
                                <v-btn color="primary" block :loading="loadingSaveNote" @click="saveNoteZeroList()"> Save </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    <div v-if="user.role == 'A' || user.role == 'E' || (user.role == 'K' && !is_print)">
                        <img src="../assets/Ideal power logo.png" style="height: 90px; width: 183px;">
                        <span class="text-center" style="display: inline-block; width: 68%; font-size: 36px">
                            Ideal Power Co.
                        </span>
                    </div>
                    
                    <hr class="my-0">
                    <div class="mt-3" style="color: black;" v-if="user.role == 'A' || user.role == 'E' || (user.role == 'K' && !is_print)">
                        <h4 v-if="showSpecialStaffs == 'true'"> Special Staffs </h4>
                        <span class="float-start"> Salary List for Staff: {{ staff_id ? staffs.find(obj => obj.st_id == staff_id).staff_name : '' }} </span>
                        <span class="float-end"> Salary List For {{ month }}/{{year}} </span>
                    </div>
                    <br>
                    <div class="table-responsive mt-5" style="page-break-inside: always;" v-if="user.role == 'A' || user.role == 'E' || (user.role == 'K' && !is_print)">

                        <table class="table table-bordered table-striped text-center text-dark" style="page-break-inside: always; font-size: 12pt !important;">
                            <thead>
                                <th style="font-size: 11pt !important;"> Name </th>
                                <th style="font-size: 11pt !important;"> Job </th>
                                <th style="font-size: 11pt !important;"> Daily </th>
                                <th style="font-size: 11pt !important;"> Days </th>
                                <th style="font-size: 11pt !important;"> Hours </th>
                                <th style="font-size: 11pt !important;"> Total Salary </th>
                                <th style="font-size: 11pt !important;"> Overtime Hours </th>
                                <th style="font-size: 11pt !important;"> Food </th>
                                <th style="font-size: 11pt !important;"> Transport </th>
                                <th style="font-size: 11pt !important;"> Cabina </th>
                                <th style="font-size: 11pt !important;"> Expense </th>
                                <th style="font-size: 11pt !important;"> Fine </th>
                                <th style="font-size: 11pt !important;"> Loan </th>
                                <th style="font-size: 11pt !important;"> Accomodation </th>
                                <th style="font-size: 11pt !important;"> Salary per Month </th>
                                
                                <th style="font-size: 11pt !important;"> Total (Dinar) </th>
                                <th style="font-size: 11pt !important;"> Total (Dollar) </th>
                            </thead>
                            <tbody>
                                <tr v-for="item in sortEmployees(filtered_data.filter(obj => obj.salary_type == 'Monthly'))" :key="item.emp_id">
                                    <td style="font-size: 9pt !important;"> {{ item.employee_full_name }} </td>
                                    <td style="font-size: 9pt !important;"> {{ item.country | country_filter }} {{ item.country && item.job ? ' - ' : '' }} {{ item.job }} </td>
                                    <td style="font-size: 9pt !important;" :class="{'text-danger': getSuitableSalary(item) < 0}"> {{ (getSuitableSalary(item)).toLocaleString() }} {{ item.salary_type == 'Monthly' ? '$' : '' }} </td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': Number(item.count_present) < 0}"> {{ Number(item.count_present)  }} </td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': item.total_hour < 0}"> {{ item.total_hour }} </td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': ((getSuitableSalary(item) * (Number(item.count_present) )) ) < 0}"> {{ (getSuitableSalary(item) * (Number(item.count_present) ) ).toLocaleString() }} {{ item.salary_type == 'Monthly' ? '$' : '' }} </td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': (((item.monthly_salary/26) / 8 ) * item.total_hour) < 0}"> {{ Number((((item.monthly_salary/26) / 8 ) * item.total_hour).toFixed(2)).toLocaleString() }} {{ item.salary_type == 'Monthly' ? '$' : '' }} </td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': Number(item.food_money) + Number(item.total_food) < 0}"> {{ (Number(item.food_money) + Number(item.total_food)).toLocaleString() }} {{ item.salary_type == 'Monthly' ? '$' : '' }} </td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': Number(item.transport_money) + Number(item.total_transport) < 0}"> {{ (Number(item.transport_money) + Number(item.total_transport)).toLocaleString() }} {{ item.salary_type == 'Monthly' ? '$' : '' }} </td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': item.cabina_money < 0}"> {{ (item.cabina_money).toLocaleString() }} {{ item.salary_type == 'Monthly' ? '$' : '' }} </td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': Number(item.expense_money) + Number(item.total_expense) + Number(item.expense_by_accomodation) < 0}"> {{ (Number(item.expense_money) + Number(item.total_expense) + Number(item.expense_by_accomodation)).toLocaleString() }} {{ item.salary_type == 'Monthly' ? '$' : '' }} </td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': (item.fine_money - item.total_fine) + Number(item.fine_by_accomodation) < 0}"> {{ (((item.fine_money) - item.total_fine) + Number(item.fine_by_accomodation)).toLocaleString() }} {{ item.salary_type == 'Monthly' ? '$' : '' }} </td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': Number(item.loan_money) + Number(item.total_loan) + Number(item.loan_by_accomodation) < 0}"> {{ (Number(item.loan_money) + Number(item.total_loan) + Number(item.loan_by_accomodation)).toLocaleString() }} {{ item.salary_type == 'Monthly' ? '$' : '' }} </td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': Number(item.accomodation_money) + Number(item.total_accomodation) + Number(item.accomodation_by_accomodation) < 0}"> {{ (Number(item.accomodation_money) + Number(item.total_accomodation) + Number(item.accomodation_by_accomodation)).toLocaleString() }} {{ item.salary_type == 'Monthly' ? '$' : '' }} </td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': item.other_expense < 0}"> {{ (item.other_expense).toLocaleString() }} {{ item.salary_type == 'Monthly' ? '$' : '' }} </td>
                                   
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': Number((((getSuitableSalary(item) * (Number(item.count_present) )) ) + (((item.monthly_salary / 26) / 8 ) * item.total_hour)  + item.food_money + item.transport_money + Number(item.total_expense) + Number(item.total_transport) + Number(item.total_food) + Number(item.total_loan) + Number(item.total_accomodation) + Number(item.loan_by_accomodation) + Number(item.accomodation_by_accomodation) + item.cabina_money + item.expense_money + item.expense_by_accomodation + item.fine_money + item.loan_money + item.accomodation_money + item.other_expense + item.other_minus) * dollar_price) < 0}">
                                        {{ Math.round(Number(((getSuitableSalary(item) * (Number(item.count_present) )) + Number(((item.monthly_salary / 26) / 8 ) * item.total_hour) +   Number(item.food_money) + Number(item.transport_money) + Number(item.total_expense) + Number(item.total_transport) + Number(item.total_food) + Number(item.total_loan) + Number(item.total_accomodation) + Number(item.loan_by_accomodation) + Number(item.accomodation_by_accomodation) + Number(item.cabina_money) + Number(item.expense_money) + Number(item.expense_by_accomodation) + Number(item.fine_money) - Number(item.total_fine) + Number(item.fine_by_accomodation) + Number(item.loan_money) + Number(item.accomodation_money) + Number(item.other_expense) + Number(item.other_minus)) * dollar_price)).toLocaleString() }}
                                    </td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': (((getSuitableSalary(item) * (Number(item.count_present) )) ) + (((item.monthly_salary / 26) / 8 ) * item.total_hour)  + item.food_money + item.transport_money + Number(item.total_expense) + Number(item.total_transport) + Number(item.total_food) + Number(item.total_loan) + Number(item.total_accomodation) + Number(item.loan_by_accomodation) + Number(item.accomodation_by_accomodation) + item.cabina_money + item.expense_money + item.expense_by_accomodation + item.fine_money + item.loan_money + item.accomodation_money + item.other_expense + item.other_minus) < 0}">
                                        {{ Number((getSuitableSalary(item) * (Number(item.count_present) )  + Number(((item.monthly_salary / 26) / 8 ) * item.total_hour) +   Number(item.food_money) + Number(item.transport_money) + Number(item.total_expense) + Number(item.total_transport) + Number(item.total_food) + Number(item.total_loan) + Number(item.total_accomodation) + Number(item.loan_by_accomodation) + Number(item.accomodation_by_accomodation) + Number(item.cabina_money) + Number(item.expense_money) + Number(item.expense_by_accomodation) + Number(item.fine_money) - Number(item.total_fine) + Number(item.fine_by_accomodation) + Number(item.loan_money) + Number(item.accomodation_money) + Number(item.other_expense) + Number(item.other_minus)).toFixed(2)).toLocaleString() }}
                                        {{ item.salary_type == 'Monthly' ? '$' : '' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="font-size: 11pt !important;" class="para"> Total Dollar </td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;" class="para" :class="{'text-danger': getTotalDollar() < 0}">
                                        {{ Math.round(getTotalDollar()).toLocaleString() }}
                                        $
                                    </td>
                                </tr>

                                <tr v-for="item in sortEmployees(filtered_data.filter(obj => obj.salary_type == 'Daily'))" :key="item.emp_id">
                                    <td style="font-size: 9pt !important;"> {{ item.employee_full_name }} </td>
                                    <td style="font-size: 9pt !important;"> {{ item.country | country_filter }}  {{ item.country && item.job ? ' - ' : '' }} {{ item.job }} </td>
                                    <td style="font-size: 9pt !important;" :class="{'text-danger': item.daily_salary < 0}"> {{ (item.daily_salary).toLocaleString() }}</td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': Number(item.count_present)  < 0}"> {{ Number(item.count_present)  }} </td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': item.total_hour < 0}"> {{ item.total_hour }} </td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': ((item.daily_salary * (Number(item.count_present) )) ) < 0}"> {{ (item.daily_salary * (Number(item.count_present) ) ).toLocaleString() }}</td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': ((item.daily_salary / 8 ) * item.total_hour) < 0}"> {{ ((item.daily_salary / 8 ) * item.total_hour).toLocaleString() }}</td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': Number(item.food_money) + Number(item.total_food) < 0}"> {{ (Number(item.food_money) + Number(item.total_food)).toLocaleString() }}</td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': Number(item.transport_money) + Number(item.total_transport) < 0}"> {{ (Number(item.transport_money) + Number(item.total_transport)).toLocaleString() }}</td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': item.cabina_money < 0}"> {{ (item.cabina_money).toLocaleString() }}</td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': Number(item.expense_money) + Number(item.total_expense) + Number(item.expense_by_accomodation) < 0}"> {{ (Number(item.expense_money) + Number(item.total_expense) + Number(item.expense_by_accomodation)).toLocaleString() }}</td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': (item.fine_money - item.total_fine) + Number(item.fine_by_accomodation) < 0}"> {{ ((item.fine_money - item.total_fine) + Number(item.fine_by_accomodation)).toLocaleString() }}</td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': Number(item.loan_money) + Number(item.total_loan) + Number(item.loan_by_accomodation) < 0}"> {{ (Number(item.loan_money) + Number(item.total_loan) + Number(item.loan_by_accomodation)).toLocaleString() }}</td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': Number(item.accomodation_money) + Number(item.total_accomodation) + Number(item.accomodation_by_accomodation) < 0}"> {{ (Number(item.accomodation_money) + Number(item.total_accomodation) + Number(item.accomodation_by_accomodation)).toLocaleString() }}</td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': item.other_expense < 0}"> {{ (item.other_expense).toLocaleString() }}</td>
                                    
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': (((item.daily_salary * (Number(item.count_present) ))  + ((item.daily_salary / 8 ) * item.total_hour)  + (item.food_money) - item.total_fine + item.transport_money + Number(item.fine_by_accomodation) + Number(item.total_expense) + Number(item.total_transport) + Number(item.total_food) + Number(item.total_loan) + Number(item.total_accomodation) + Number(item.loan_by_accomodation) + Number(item.accomodation_by_accomodation) + item.cabina_money + item.expense_money + item.expense_by_accomodation + item.fine_money + item.loan_money + item.accomodation_money + item.other_expense + item.other_minus) ) < 0}">
                                        {{ Math.round((Math.round(item.daily_salary * (Number(item.count_present) ))  + Math.round((item.daily_salary / 8 ) * item.total_hour)  + (item.food_money) - item.total_fine + item.transport_money + Number(item.fine_by_accomodation) + Number(item.total_expense) + Number(item.total_transport) + Number(item.total_food) + Number(item.total_loan) + Number(item.total_accomodation) + Number(item.loan_by_accomodation) + Number(item.accomodation_by_accomodation) + item.cabina_money + item.expense_money + item.expense_by_accomodation + item.fine_money + item.loan_money + item.accomodation_money + item.other_expense + item.other_minus) ).toLocaleString() }}
                                        {{ item.salary_type == 'Monthly' ? '$' : '' }}
                                    </td>
                                    <td style="font-size: 11pt !important;" :class="{'text-danger': (((item.daily_salary * (Number(item.count_present) ))  + ((item.daily_salary / 8 ) * item.total_hour)  + (item.food_money) - item.total_fine + item.transport_money + Number(item.fine_by_accomodation) + Number(item.total_expense) + Number(item.total_transport) + Number(item.total_food) + Number(item.total_loan) + Number(item.total_accomodation) + Number(item.loan_by_accomodation) + Number(item.accomodation_by_accomodation) + item.cabina_money + item.expense_money + item.expense_by_accomodation + item.fine_money + item.loan_money + item.accomodation_money + item.other_expense + item.other_minus)/ dollar_price) < 0}">
                                        {{ Math.round(((item.daily_salary * (Number(item.count_present) ))  + ((item.daily_salary / 8 ) * item.total_hour)  + (item.food_money) - item.total_fine + item.transport_money + Number(item.fine_by_accomodation) + Number(item.total_expense) + Number(item.total_transport) + Number(item.total_food) + Number(item.total_loan) + Number(item.total_accomodation) + Number(item.loan_by_accomodation) + Number(item.accomodation_by_accomodation) + item.cabina_money + item.expense_money + item.expense_by_accomodation + item.fine_money + item.loan_money + item.accomodation_money + item.other_expense + item.other_minus)/ dollar_price).toLocaleString() }}
                                        $
                                    </td>
                                </tr>
                                <tr>
                                    <td style="font-size: 11pt !important;" class="para"> Total Dinar </td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    
                                    <td style="font-size: 11pt !important;" class="para" :class="{'text-danger': getTotalDinar() < 0}">
                                        {{ Math.round(getTotalDinar()).toLocaleString() }}
                                    </td>
                                    <td style="font-size: 11pt !important;"></td>
                                </tr>
                                <tr>
                                    <td style="font-size: 11pt !important;" class="para-total"> Total Dinar and Dollar </td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    <td style="font-size: 11pt !important;"></td>
                                    
                                    <td style="font-size: 11pt !important;" class="para-total" :class="{'text-danger': lastTotalDinar() < 0}">
                                        {{ Math.round(lastTotalDinar()).toLocaleString() }}
                                    </td>
                                    <td style="font-size: 11pt !important;" class="para-total" :class="{'text-danger': lastTotalDollar() < 0}">
                                        {{ Math.round(lastTotalDollar()).toLocaleString() }} $
                                    </td>
                                    
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <export-excel :data="excel_data" v-if="user.role == 'A' || user.role == 'K'">
                        <v-btn color="success" small class="mt-2 no-print"> <v-icon class="mr-3"> mdi-microsoft-excel </v-icon> Download Excel </v-btn>
                    </export-excel>
                    <br><br>
                    <div class="row mt-10" style="color: black;" v-if="user.role == 'A' || user.role == 'E' || (user.role == 'K' && !is_print)">
                        <div class="col-3 justify-content-center text-center">
                            <span>
                                بەڕێوبەر
                            </span>
                            <br>
                            <span> {{ manager }} </span>
                        </div>
                        <div class="col-3 justify-content-center text-center">
                            <span>
                                ب.دارایی
                            </span>
                            <br>
                            <span> {{ kargeri }} </span>
                        </div>
                        <div class="col-3 justify-content-center text-center">
                            <span>
                                سەرپەرشتیاری ستاف
                            </span>
                            <br>
                            <span> {{ supervisor }} </span>
                        </div>
                        <div class="col-3 justify-content-center text-center">
                            <img :src="h_signature" width="180px" height="130px" class="only-print" style="margin-left: 38px; margin-top: -90px;" alt="">
                            <div class="mt-n10">
                                <span>
                                    ژمێریار
                                </span>
                                <br>
                                <span> {{ accountant }} </span>
                                <br>
                                <span> {{ new Date().toISOString().split('T')[0] }} </span>
                                <br>
                                <span> {{ currentTime }} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdlDaydAndOvertime">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="mt-2"> Added Days and Overtime </h5>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped text-center">
                                <thead>
                                    <th> Name </th>
                                    <th style="width: 25%;"> Added Days </th>
                                    <th style="width: 25%;"> Overtime Hours </th>
                                </thead>
                                <tbody>
                                    <tr v-for="item in filtered_data" :key="item.emp_id">
                                        <td> {{ item.employee_full_name }} </td>
                                        <td>
                                            <input type="number" style="text-align: center;" v-model="item.added_days" class="form-control" @change="changeAddedDays(item)">
                                        </td>
                                        <td>
                                            <input type="number" style="text-align: center;" v-model="item.added_overtime" class="form-control" @change="changeAddedOvertime(item)">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> 
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdlTotalSelection">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="mt-2"> Totals </h5>
                    </div>
                    <div class="modal-body">
                        <a :href="'/total_salary_list/' + month + '/' + year + '/' + dollar_price + '/normals'" class="btn btn-primary btn-block text-white" > Total </a>
                        <a :href="'/total_salary_list/' + month + '/' + year + '/' + dollar_price + '/specials'" class="btn btn-primary btn-block text-white" > Total Specials </a>
                    </div>
                </div>
            </div> 
        </div>

    </div>
</template>

<script>
import auth from '../auth';
// import showError from '../showError.js';
export default {
    name: 'Salary_List',
    data() {
        return {
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
            staff_id: null,
            salary_list: [],
            staffs: [],
            dollar_price: 0,
            user: {
                username: '',
                role: '',
                en_id: null
            },
            manager: '',
            kargeri: '',
            supervisor: '',
            accountant: '',
            employees: [],
            emp_id: null,
            employees_to_zero: [],
            currentTime: new Date().toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}),
            is_print: false,
            getZerolLoading: false,
            changeZeroLoading: false,
            dialogNoteZeroList: false,
            note_zero_list: null,
            loadingSaveNote: false,
            selected_emp_id: null,
            showSpecialStaffs: "false",
            h_signature: require('../assets/h-signature.jpg')
        }
    },
    beforeCreate(){
        auth("salaryList");
    },
    created(){
        this.$http.get('/getNames').then(({data}) => {
            this.manager = data.manager;
            this.kargeri = data.kargeri;
            this.accountant = data.accountant;
        });
        this.$http.post("/getLoggedInfo").then(({data}) => {
            this.user.username = data.username;
            this.user.role = data.type;
            this.user.en_id = data.en_id || null;

            if(this.user.en_id != null){
                this.$http.post("/staff/getByEngineer/" + this.user.en_id).then(({data}) => {
                    this.staffs = data
                });
            } else {
                this.$http.post("/staff/getData").then(({data}) => {
                    this.staffs = data.filter(obj => obj.show_staff == '1').sort((a, b) => a.staff_sort_code - b.staff_sort_code)
                });
            }
        });
    },

    mounted() {
        setInterval(() => {
            this.currentTime = new Date().toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
        }, 1 * 60 * 1000);

        window.matchMedia('print').addListener(e => {
            if(e.matches) {
                this.is_print = true;
            } else {
                this.is_print = false;
            }
        });
    },
   
    methods: {
        search_staff(){
            this.$http.post('/employee/getEmployeeBystaff/'+this.staff_id + '/' + this.month + '/' + this.year).then(([data])=>{
                this.employees_btn.full_name=data.full_name;
            })
        },
        search() {
            if(this.month && this.year && this.staff_id) {
                this.getZerolLoading = true;
                this.salary_list = []
                this.employees_to_zero = []
                this.$http.post('/employee/getSalaryListByMonthAndYear', {
                    month: this.month,
                    year: this.year,
                    staff_id: this.staff_id
                }).then(({data}) => {
                    this.salary_list = data.salary_list.filter((obj, index, self) =>
                        index === self.findIndex((o) => o.emp_id === obj.emp_id)
                    ) 
                    this.employees_to_zero = data.zeros.map(obj => obj.emp_id);
                    this.getZerolLoading = false;
                    this.overWorkDayToOvertime()
                });
            }
        },
        getSuitableSalary(item) {
            // var d = new Date();
            // d.setMonth(this.month - 1);
            // d.setFullYear(this.year);
            const allDaysInMonth = this.getDaysAndFridays(this.month, this.year).days;
            const numberOfFridays = this.getDaysAndFridays(this.month, this.year).fridays;
            const days = allDaysInMonth - numberOfFridays;
            if(item.salary_type == 'Monthly') {
                item.daily_salary = Number(item.monthly_salary / days).toFixed(2);
                return Number(Number(item.monthly_salary / days).toFixed(2));
            } else {
                return (item.daily_salary);
            }
        },
        getDaysAndFridays(month, year) {
            const totalDays = new Date(year, month, 0).getDate();
            let fridays = 0;

            for (let day = 1; day <= totalDays; day++) {
                const currentDate = new Date(year, month - 1, day);
                if (currentDate.getDay() === 5) {
                fridays++;
                }
            }

            return {
                days: totalDays,
                fridays: fridays
            };
        },
        fridays(year, month) {
            var day, counter, date;
            day = 1;
            counter = 0;
            date = new Date(year, month, day);
            
            while (date.getMonth() === month) {
                if (date.getDay() === 5) { // Sun=0, Mon=1, Tue=2, etc.
                    counter += 1;
                }
                day += 1;
                date = new Date(year, month, day);
            }
            return counter;
        },
        getTotalDinar() {
            return this.filtered_data.filter(obj => obj.salary_type == 'Daily').reduce((a, b) => a + ((this.getSuitableSalary(b) * (Number(b.count_present))) - Number(b.total_fine) + Number(b.fine_by_accomodation) + ((this.getSuitableSalary(b) / 8) * b.total_hour)  + b.food_money + b.transport_money + Number(b.total_expense) + Number(b.total_transport) + Number(b.total_food) + Number(b.total_loan) + Number(b.total_accomodation) + Number(b.loan_by_accomodation) + Number(b.accomodation_by_accomodation) + b.cabina_money + b.expense_money + b.expense_by_accomodation + b.fine_money + b.loan_money + b.accomodation_money + b.other_expense + b.other_minus), 0)
        },
        getTotalDollar() {
            return this.filtered_data.filter(obj => obj.salary_type == 'Monthly').reduce((a, b) => a + ((this.getSuitableSalary(b) * (Number(b.count_present))) - Number(b.total_fine) + Number(b.fine_by_accomodation) + (((b.monthly_salary / 26) / 8) * b.total_hour)  + b.food_money + b.transport_money + Number(b.total_expense) + Number(b.total_transport) + Number(b.total_food) + Number(b.total_loan) + Number(b.total_accomodation) + Number(b.loan_by_accomodation) + Number(b.accomodation_by_accomodation) + b.cabina_money + b.expense_money + b.expense_by_accomodation + b.fine_money + b.loan_money + b.accomodation_money + b.other_expense + b.other_minus), 0)
        },
        lastTotalDollar() {
            return this.getTotalDollar() + (this.getTotalDinar() / (this.dollar_price || 0));
        },
        lastTotalDinar() {
            return this.getTotalDinar() + (this.getTotalDollar() * (this.dollar_price || 0));
        },
        changeNames() {
            this.$http.post('/setNames', {
                manager: this.manager,
                kargeri: this.kargeri,
                accountant: this.accountant
            }).then(() => {
                
            })
        },
        overWorkDayToOvertime() {
            for(let i = 0; i < this.salary_list.length; i++) {
                this.salary_list[i].count_present = Number(this.salary_list[i].count_present) + Number(this.salary_list[i].added_days);
                this.salary_list[i].total_hour = Number(this.salary_list[i].total_hour) + Number(this.salary_list[i].added_overtime);
                var count_present = Number(this.salary_list[i].count_present);
                const allDaysInMonth = this.getDaysAndFridays(this.month, this.year).days;
                const numberOfFridays = this.getDaysAndFridays(this.month, this.year).fridays;
                const days = allDaysInMonth - numberOfFridays;
                
                if(count_present > days) {
                    var overtimeDays = count_present - days;
                    this.salary_list[i].total_hour += (overtimeDays * 8);
                    this.salary_list[i].count_present = days;
                }
            }
        },
        changeAddedDays(item) {
            this.$http.post('/gived_salary/changeAddedDays', {
                gs_id: item.gs_id,
                added_days: item.added_days
            }).then(() => {
                this.search()
            })
        },
        changeAddedOvertime(item) {
            this.$http.post('/gived_salary/changeAddedOvertime', {
                gs_id: item.gs_id,
                added_overtime: item.added_overtime
            }).then(() => {
                this.search()
            })
        },
        sortEmployees(list) {
            return list.sort((a, b) => a.sort_code - b.sort_code)
        },
        checkZeroOrNot(emp_id) {
            const inZeroList = this.employees_to_zero.includes(emp_id);
            if(inZeroList) {
                this.changeZeroLoading = true;
                this.$http.post('/employee/addZeroList', {
                    list: [{st_id: this.staff_id, month: this.month, year: this.year, emp_id: emp_id}]
                }).then(() => {
                    this.employees_to_zero.push(emp_id);
                    this.changeZeroLoading = false;
                    this.note_zero_list = null;
                    this.selected_emp_id = emp_id;
                    this.dialogNoteZeroList = true;
                })
            } else {
                this.changeZeroLoading = true;
                this.$http.delete('/employee/deleteZeroList/' + this.year + '/' + this.month + '/' + this.staff_id + '/' + emp_id).then(() => {
                    this.changeZeroLoading = false;
                })
                
            }
        },
        saveNoteZeroList() {
            this.loadingSaveNote = true;
            this.$http.post('/employee/saveNoteZeroList/' + this.month + '/' + this.year + '/' + this.staff_id + '/' + this.selected_emp_id, {
                note: this.note_zero_list,
            }).then(() => {
                this.$alert('Note Saved', "Success", "success");
                this.dialogNoteZeroList = false;
                this.selected_emp_id = null
            }).finally(() => {
                this.loadingSaveNote = false;
            })
        }
    },
    computed: {
        filtered_data() {
            let arr = []
            if(this.emp_id) {
                arr = this.salary_list.filter(obj => obj.emp_id == this.emp_id)
            } else {
                arr = this.salary_list
            }
            return arr.sort((a, b) => a.sort_code - b.sort_code)
        },
        excel_data() {
            const arr = []
            arr.push(...this.salary_list.filter(obj => obj.salary_type == 'Monthly').sort((a, b) => a.sort_code - b.sort_code).map(item => {
                return {
                    'Name': item.employee_full_name,
                    'Job': (item.country ? this.$options.filters.country_filter(item.country) : '') + (item.country && item.job ? ' - ' : '') + (item.job || ''),
                    'Daily': (item.salary_type == 'Monthly' ? '$' : '') + this.getSuitableSalary(item),
                    'Days': (Number(item.count_present) ),
                    'Hours': item.total_hour,
                    'Total Salary': (item.salary_type == 'Monthly' ? '$' : '') + (this.getSuitableSalary(item) * (Number(item.count_present) )),
                    'Overtime Hours': (item.salary_type == 'Monthly' ? '$' : '') + Number((((item.monthly_salary/26) / 8 ) * item.total_hour).toFixed(2)),
                    'Food': (item.salary_type == 'Monthly' ? '$' : '') + (Number(item.food_money) + Number(item.total_food)),
                    'Transport': (item.salary_type == 'Monthly' ? '$' : '') + (Number(item.transport_money) + Number(item.total_transport)),
                    'Cabina': (item.salary_type == 'Monthly' ? '$' : '') + (item.cabina_money),
                    'Expense': (item.salary_type == 'Monthly' ? '$' : '') + (Number(item.expense_money) + Number(item.total_expense) + Number(item.expense_by_accomodation)),
                    'Fine': (item.salary_type == 'Monthly' ? '$' : '') + (((item.fine_money) - item.total_fine) + Number(item.fine_by_accomodation)),
                    'Loan': (item.salary_type == 'Monthly' ? '$' : '') + (Number(item.loan_money) + Number(item.total_loan) + Number(item.loan_by_accomodation)),
                    'Accomodation': (item.salary_type == 'Monthly' ? '$' : '') + (Number(item.accomodation_money) + Number(item.total_accomodation) + Number(item.accomodation_by_accomodation)),
                    'Salary per Month': (item.salary_type == 'Monthly' ? '$' : '') + (item.other_expense),
                    
                    'Total (Dinar)': Math.round(Number(((this.getSuitableSalary(item) * (Number(item.count_present) )) + Number(((item.monthly_salary / 26) / 8 ) * item.total_hour) +   Number(item.food_money) + Number(item.transport_money) + Number(item.fine_by_accomodation) + Number(item.total_expense) + Number(item.total_transport) + Number(item.total_food) + Number(item.total_loan) + Number(item.total_accomodation) + Number(item.loan_by_accomodation) + Number(item.accomodation_by_accomodation) + Number(item.cabina_money) + Number(item.expense_money) + Number(item.expense_by_accomodation) + Number(item.fine_money) - Number(item.total_fine) + Number(item.loan_money) + Number(item.accomodation_money) + Number(item.other_expense) + Number(item.other_minus)) * this.dollar_price)).toLocaleString(),
                    'Total (Dollar)': (item.salary_type == 'Monthly' ? '$' : '') + (Number((this.getSuitableSalary(item) * (Number(item.count_present) )  + Number(((item.monthly_salary / 26) / 8 ) * item.total_hour) +   Number(item.food_money) + Number(item.fine_by_accomodation) + Number(item.transport_money) + Number(item.total_expense) + Number(item.total_transport) + Number(item.total_food) + Number(item.total_loan) + Number(item.total_accomodation) + Number(item.loan_by_accomodation) + Number(item.accomodation_by_accomodation) + Number(item.cabina_money) + Number(item.expense_money) + Number(item.expense_by_accomodation) + Number(item.fine_money) - Number(item.total_fine) + Number(item.loan_money) + Number(item.accomodation_money) + Number(item.other_expense) + Number(item.other_minus)).toFixed(2)))
                }
            }))

            arr.push({
                'Name': 'Total Dollar',
                'Job': '',
                'Daily': '',
                'Days': '',
                'Hours': '',
                'Total Salary': '',
                'Overtime Hours': '',
                'Food': '',
                'Transport': '',
                'Cabina': '',
                'Expense': '',
                'Fine': '',
                'Loan': '',
                'Accomodation': '',
                'Salary per Month': '',
                'Total (Dinar)': '',
                'Total (Dollar)': '$' + Math.round(this.getTotalDollar())
            })

            arr.push(...this.salary_list.filter(obj => obj.salary_type == 'Daily').sort((a, b) => a.sort_code - b.sort_code).map((item) => {
                return {
                    'Name': item.employee_full_name,
                    'Job': (item.country ? this.$options.filters.country_filter(item.country) : '') + (item.country && item.job ? ' - ' : '') + (item.job || ''),
                    'Daily': Number(item.daily_salary).toLocaleString(),
                    'Days': (Number(item.count_present) ),
                    'Hours': (item.total_hour),
                    'Total Salary': Number(item.daily_salary * (Number(item.count_present) ) ).toLocaleString(),
                    'Overtime Hours': Number((item.daily_salary / 8 ) * item.total_hour).toLocaleString(),
                    'Food': (Number(item.food_money) + Number(item.total_food)).toLocaleString(),
                    'Transport': (Number(item.transport_money) + Number(item.total_transport)).toLocaleString(),
                    'Cabina': Number(item.cabina_money).toLocaleString(),
                    'Expense': (Number(item.expense_money) + Number(item.total_expense) + Number(item.expense_by_accomodation)).toLocaleString(),
                    'Fine': Number((item.fine_money - item.total_fine) + Number(item.fine_by_accomodation)).toLocaleString(),
                    'Loan': Number(Number(item.loan_money) + Number(item.total_loan) + Number(item.loan_by_accomodation)).toLocaleString(),
                    'Accomodation': Number(Number(item.accomodation_money) + Number(item.total_accomodation) + Number(item.accomodation_by_accomodation)).toLocaleString(),
                    'Salary per Month': Number(item.other_expense).toLocaleString(),
                    'Total (Dinar)': Math.round((Math.round(item.daily_salary * (Number(item.count_present) ))  + Math.round((item.daily_salary / 8 ) * item.total_hour)  + (item.food_money) - item.total_fine + item.transport_money + Number(item.fine_by_accomodation) + Number(item.total_expense) + Number(item.total_transport) + Number(item.total_food) + Number(item.total_loan) + Number(item.total_accomodation) + Number(item.loan_by_accomodation) + Number(item.accomodation_by_accomodation) + item.cabina_money + item.expense_money + item.expense_by_accomodation + item.fine_money + item.loan_money + item.accomodation_money + item.other_expense + item.other_minus) ).toLocaleString(),
                    'Total (Dollar)': '$' + Math.round(((item.daily_salary * (Number(item.count_present) ))  + ((item.daily_salary / 8 ) * item.total_hour)  + (item.food_money) - item.total_fine + item.transport_money + Number(item.fine_by_accomodation) + Number(item.total_expense) + Number(item.total_transport) + Number(item.total_food) + Number(item.total_loan) + Number(item.total_accomodation) + Number(item.loan_by_accomodation) + Number(item.accomodation_by_accomodation) + item.cabina_money + item.expense_money + item.expense_by_accomodation + item.fine_money + item.loan_money + item.accomodation_money + item.other_expense + item.other_minus)/ this.dollar_price)
                }
            }))

            arr.push({
                'Name': 'Total Dinar',
                'Job': '',
                'Daily': '',
                'Days': '',
                'Hours': '',
                'Total Salary': '',
                'Overtime Hours': '',
                'Food': '',
                'Transport': '',
                'Cabina': '',
                'Expense': '',
                'Fine': '',
                'Loan': '',
                'Accomodation': '',
                'Salary per Month': '',
                'Total (Dinar)': Math.round(this.getTotalDinar()).toLocaleString(),
                'Total (Dollar)': ''
            })

            arr.push({
                'Name': 'Total Dinar and Dollar',
                'Daily': '',
                'Job': '',
                'Days': '',
                'Hours': '',
                'Total Salary': '',
                'Overtime Hours': '',
                'Food': '',
                'Transport': '',
                'Cabina': '',
                'Expense': '',
                'Fine': '',
                'Loan': '',
                'Accomodation': '',
                'Salary per Month': '',
                'Total (Dinar)': Math.round(this.lastTotalDinar()).toLocaleString(),
                'Total (Dollar)': '$' + Math.round(this.lastTotalDollar())
            })

            return arr
        },
        
    },
    watch: {
        staff_id(value) {
            this.search();
            this.employees = [];
            this.emp_id = null
            if(value){
                this.supervisor = this.staffs.find(obj => obj.st_id == value).staff_name;
                this.$http.post("/employee/getEmployeeBystaff/" + this.staff_id + '/' + this.month + '/' + this.year).then(({data}) => {
                    this.employees = data;
                });
            } else {
                this.employees = [];
            }
        },
        month() {
            this.search();
        },
        year() {
            this.search();
        },
        employees_to_zero() {
            // const newIds = newArr.filter(id => !oldArr.includes(id));
            // const oldIds = oldArr.filter(id => !newArr.includes(id));
            // if(newIds.length > 0 && this.staff_id && this.year && this.month) {
            //     this.$http.post('/employee/addZeroList', {
            //         list: [{st_id: this.staff_id, month: this.month, year: this.year, emp_id: newIds[0]}]
            //     }).then(() => {
                    
            //     })
            // }
            // if(oldIds.length > 0 && this.staff_id && this.year && this.month) {
            //     this.$http.delete('/employee/deleteZeroList/' + this.year + '/' + this.month + '/' + this.staff_id + '/' + oldIds[0]).then(() => {
                    
            //     })
            // }
            for(let i = 0; i< this.salary_list.length; i++) {
                if(this.employees_to_zero.includes(this.salary_list[i].emp_id)) {
                    this.salary_list[i].daily_salary = 0;
                    this.salary_list[i].monthly_salary = 0;
                    this.salary_list[i].total_o = 0;
                    this.salary_list[i].total_hour = 0;
                    this.salary_list[i].total_fine = 0;
                    this.salary_list[i].fine_by_accomodation = 0;
                    this.salary_list[i].count_present = 0;
                    this.salary_list[i].added_days = 0;
                    this.salary_list[i].added_overtime = 0;
                    this.salary_list[i].food_money = 0;
                    this.salary_list[i].total_food = 0;
                    this.salary_list[i].total_transport = 0;
                    this.salary_list[i].total_loan = 0;
                    this.salary_list[i].total_expense = 0;
                    this.salary_list[i].total_accomodation = 0;
                    this.salary_list[i].transport_money = 0;
                    this.salary_list[i].cabina_money = 0;
                    this.salary_list[i].expense_money = 0;
                    this.salary_list[i].expense_by_accomodation = 0;
                    this.salary_list[i].fine_money = 0;
                    this.salary_list[i].loan_money = 0;
                    this.salary_list[i].loan_by_accomodation = 0;
                    this.salary_list[i].accomodation_money = 0;
                    this.salary_list[i].accomodation_by_accomodation = 0;
                    this.salary_list[i].other_expense = 0;
                    this.salary_list[i].other_minus = 0;
                }
            }
           
        }
    },
    filters: {
        country_filter(value) {
            return {
                "1": "Iraq",
                "2": "Foreign",
                "3": "Iran",
                "4": "Bangladish",
                "5": "Syria",
                "6": "India",
                "7": "Pakistan",
            }[value]
        }
    }
}
</script>

<style scoped>
    .rtl {
        text-align: right !important;
    }
    tbody td {
        padding: .5rem;
    }
    .para {
        background-color: rgb(81, 224, 81) !important;
        color: black !important;
    }
    .para-total {
        background-color: rgb(68, 171, 231) !important;
        color: black !important;
    }
    th, td {
        font-size: 11pt;
        /* border: 1px solid black !important; */
    }
    th, td:not(.text-danger) {
        color: rgb(17, 17, 17);
    }
    .only-print {
        display: none;
    }
    @media print {
        .no-print, #nav {
            display: none;
        }
        .only-print {
            display: inherit;
        }
        *:not(.para, .para-total, .expired-passport-date) {
            /* background-color: white ; */
            
        }
        .para {
            background-color: rgb(81, 224, 81) !important;
            color: black !important;
        }
        .para-total {
            background-color: rgb(68, 171, 231) !important;
            color: black !important;
        }
        #wrapper #content-wrapper {
            background-color: white !important;
        }
        table {
            font-size: 9pt !important;
        }
        @page {
            size: landscape;
        }
    }
</style>